@font-face {
    font-family: Rouble;
    	src: url('../fonts/rouble.eot?') format('eot'),
			 url('../fonts/rouble.woff') format('woff'),
		 	 url('../fonts/rouble.otf') format('opentype'),
        	 url('../fonts/rouble.ttf')  format('truetype'),
        	 url('../fonts/rouble.svg#ALSRubl') format('svg');
}



@font-face {
	font-family: 'ruble';
	src: url('../fonts/rubleLig.eot');
	src: url('../fonts/rubleLig.otf') format('opentype'),
	url('../fonts/rubleLig.woff') format('woff'),
	url('../fonts/rubleLig.ttf')  format('truetype'),
	url('../fonts/rubleLig.svg#svgruble') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'rubleBold';
	src: url('../fonts/rubleLig.eot');
	src: url('../fonts/rubleLig.otf') format('opentype'),
	url('../fonts/rubleLig.woff') format('woff'),
	url('../fonts/rubleLig.ttf')  format('truetype'),
	url('../fonts/rubleLig.svg#svgruble') format('svg');
	font-weight: bold;
	font-style: normal;
}

.money-formatted {
	font-family: 'ruble', Arial, sans-serif;
	font-weight: normal;
	-webkit-font-feature-settings: 'dlig';
	-moz-font-feature-settings: 'dlig=1';
	-moz-font-feature-settings: 'dlig';
	-moz-font-feature-settings: 'dlig' 1;
	font-feature-settings: 'dlig';
	text-rendering: optimizeLegibility;
}
.money-formatted-italic {
	font-family: ruble, Arial,sans-serif;
	font-style: italic;
	-webkit-font-feature-settings: 'dlig';
	-moz-font-feature-settings: 'dlig=1';
	-moz-font-feature-settings: 'dlig';
	-moz-font-feature-settings: 'dlig' 1;
	font-feature-settings: 'dlig';
	text-rendering: optimizeLegibility;
}
.money-formatted-bold, .money-formatted b {
	font-family: 'rubleBold', Arial,sans-serif;
	font-weight: bold;
	-webkit-font-feature-settings: 'dlig';
	-moz-font-feature-settings: 'dlig=1';
	-moz-font-feature-settings: 'dlig';
	-moz-font-feature-settings: 'dlig' 1;
	font-feature-settings: 'dlig';
	text-rendering: optimizeLegibility;
}


html, body { position: relative; margin: 0; padding: 0; }
html { height: 100%; font-size: 100.1%; }
body { height: 100%; min-height: 100%; background-color: white; font: 1em/1.2 "Helvetica Neue",Helvetica,Arial,sans-serif;}
body ul { margin: 0; padding: 0; list-style: none;}
body ul li { margin: 0;}

body {
	min-width: 1000px;
}

.gray { color: #666666; }
.tgray { color: #aaaaaa; }
.black { color: #000000;}

.clear { clear: both; }
.subH1 { margin-top: -3px; }
.subT { margin-top: -5px; }
.wideColumn { padding: 0 20px; }

.nowrap { white-space: nowrap; }
.main .invisible { display: none !important;}
.cur_pointer { cursor: pointer ;}
b, .bold { font-weight: bold; }
.link { color: #2b76ac; cursor: pointer; text-decoration: underline; }
.dashed { text-decoration: none; border-bottom: 1px dashed; cursor: pointer; }
.normal-list .link {
    color: #0088cc;
    text-decoration: none;
    border-bottom: 1px dashed transparent;
}
.normal-list .link:hover {
    text-decoration: underline;
    color: #2b76ac;
}
.noMargin { margin: 0px !important;}
.inline { display: inline-block;//display: inline;}
.floatLeft { float: left !important;}
.floatRight { float: right !important;}

.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
#content { min-height: 100%; font-size: 90%;}
#footer {
	background-color: #EEEEEE;
	background-image: url('../img/footer_bg.jpg');
	background-repeat: repeat-x;
	background-position: 0 0;
	position: relative;
	margin-top: -14em;
	clear: both;
}
#clearfooter, #footer { height: 250px;}
#footer.side-clear { margin-top: -9em;}
#clearfooter.side-bottom, #footer.side-bottom { height: 9em;}

.top { background-color: black; height: 50px; position: relative; z-index: 2; padding-left: 70px;}
.top * { vertical-align: middle;}
.top .ott_logo { margin-top: 5px;}
.main { max-width: 954px; padding-left: 50px; color: #111; }
.top .main {background-color: black;}
.body { padding-top: 35px; position: relative; z-index: 1}

#footer .main { padding-top: 15px;}
#footer ul { font-size: 90%; margin-top: 10px; }
#footer ul a { color: #666666;}
#footer .copyright { padding-top: 15px; font-size: 85%;}

.abs, .abs>* { position: absolute; background-repeat: no-repeat; }
.abs>.t { top: 0; background-position: top; background-repeat: repeat-x; }
.abs>.b { bottom: 0; background-position: bottom; background-repeat: repeat-x; }
.abs>.l { left: 0; background-position: left; background-repeat: repeat-y; }
.abs>.r { right: 0; background-position: right; background-repeat: repeat-y; }
.abs>.l, .abs>.r, .abs>.c {/* Opera */ padding: 0%; }
.abs>.tl { top: 0; left: 0; background-position: top left; }
.abs>.tr { top: 0; right: 0; background-position: top right; }
.abs>.bl { bottom: 0; left: 0; background-position: bottom left; }
.abs>.br { bottom: 0; right: 0; background-position: bottom right; }

.panelDropMenu { font-size: 90%; display: inline-block; //display: inline; margin-left: 30px; color: white;}
.panelDropMenu .dm_wrapper { display: inline-block; position: relative; }
.panelDropMenu ul { background-color: black; position: absolute; top: 29px; white-space: nowrap; padding: 0; margin: 0; display: none;
	-webkit-border-bottom-left-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-radius: 0 0 5px 5px;
	-moz-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;
}
.panelDropMenu ul li *, .panelDropMenu .dm_selected * {vertical-align: middle;}
.panelDropMenu .dm_selected { position: relative; padding: 7px 20px 7px 10px; cursor: pointer; white-space: nowrap; position: relative;}

.panelDropMenu ul li {padding: 5px 10px; cursor: pointer;}
.panelDropMenu li:hover  { background-color: #4c4c4c;
	-webkit-transition: .2s background-color;
	   -moz-transition: .2s background-color;
		 -o-transition: .2s background-color;
			transition: .2s background-color;
}
.panelDropMenu li:last-child {
	-webkit-border-bottom-left-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-radius: 0 0 5px 5px;
	   -moz-border-radius: 0 0 5px 5px;
			border-radius: 0 0 5px 5px;
}
.panelDropMenu .dm_selected:after { content: ''; position: absolute; top: -4px; bottom: 0; margin: auto 0; right: 5px; width: 0px; height: 0px; border-style: solid; border-width: 4px 4px 0; border-color: #ffffff transparent transparent; }
ul#topMenu li:not(.selected):hover, .panelDropMenu .dm_selected:hover  { background-color: #4c4c4c;
	-webkit-transition: .4s background-color;
	   -moz-transition: .4s background-color;
		 -o-transition: .4s background-color;
			transition: .4s background-color;
}
ul#topMenu li a, .panelDropMenu li a { text-decoration: none; color: white; }
.panelDropMenu ul li.link { padding: 0;}
.panelDropMenu ul li.link a {padding: 5px 10px; display: inline-block;}

.lang {  }
.lang:before { content: ''; display: inline-block; vertical-align: middle; margin-right: 6px; width: 24px; height: 19px; background: url('../img/languages2.png') no-repeat; }
.lang.ru:before, .lang.ru-us:before { background-position: 0px 0px; }
.lang.uk-ua:before, .lang.ru-ua:before { background-position: -24px 0px; }
.lang.az:before { background-position: -48px 0px; }
.lang.en:before { background-position: -72px 0px; }
.lang.en-gb:before { background-position: -72px 0px; }
.lang.de:before { background-position: -96px 0px; }
.lang.es:before { background-position: -120px 0px; }
.lang.tr:before { background-position: -144px 0px; }
.lang.tr:before { background-position: -144px 0px; }
.lang.br:before { background-position: -168px 0px; }
.lang.en-us:before { background-position: -192px 0px; }
.lang.en-ie:before { background-position: -216px 0px; }
.lang.kz:before, .lang.ru-kz:before  { background-position: -240px 0px; }
.lang.pl:before { background-position: -264px 0px; }
.lang.ru-by:before { background-position: -288px 0px; }


#languageSelect ul li { padding: 5px; }
#languageSelect .dm_selected { padding: 7px 20px 7px 35px; }
#languageSelect .dm_selected .lang { position: absolute; left: 5px; top: 5px;}

#layout_ticket .fareRules { color: #005580; }
#fareRules * { line-height: 1.2; }
#fareRules ul { display: block; margin: 0; }
#fareRules ul li { list-style: none; margin: 0; padding: 0; }
#fareRules { width: 900px; margin-left: -450px; }
#fareRules .modal-header { position: relative; height: 3px; border: none; }
#fareRules .modal-header .close { position: absolute; top: -9px; bottom: -9px; right: 5px; margin: auto 0; line-height: 18px; height: 20px; }
#fareRules .modal-body { max-height: 459px; overflow: auto; position: relative; padding: 0 15px; margin-bottom: 20px; }
#fareRules div.head { font-size: 130%; }
#fareRules div.caution { margin-bottom: 1em; font-size: 90%; }
#fareRules ul.menu { margin: 1em 0; }
#fareRules ul.menu li.selected span { border-bottom: none; color: inherit; }
#fareRules ul.rules li { display: none; }
#fareRules ul.rules li.selected { display: block; }
#fareRules div.title { font-size: 140%; font-weight: bold; margin-bottom: 0.5em; }
#fareRules div.subTitle { margin: 0.5em 0 0; font-weight: bold; }
#fareRules div.note { font-size: 90%;  margin-bottom: 0.5em; }
#fareRules div.full { white-space: pre; position: relative; font-size: 80%; border-top: 1px solid #CCC; background: #EAEAEA; margin: 0 -15px; display: inline-block; min-width: 100%;}
#fareRules div.full>div { padding: 10px 15px; }

.block_creditCard { margin: 1em 0; position: relative; max-width: 794px; }
.block_creditCard .control-group, .block_creditCard label, .block_creditCard input { margin: 0;}
.block_creditCard .control-group { position: relative; }
.block_creditCard .expDate .control-group { display: inline-block;//display: inline;}
.creditCard { z-index: 2; position: relative; display: inline-block; vertical-align: top; width: 388px; height: 236px; padding: 2px; background: white url("../img/creditCard.png") no-repeat left top;
	-webkit-border-radius: 10px;
	   -moz-border-radius: 10px;
			border-radius: 10px;
}
.creditCard.reverse { z-index: 1; position: absolute; right: 0; background-position: right top; }
.creditCard .visa-logo { position: absolute; top: 18px; right: 24px; height: 26px; width: 79px; background: url(../img/visa.png) no-repeat center; }
.creditCard .masterCard-logo { position: absolute; top: 10px; right: 113px; height: 36px; width: 60px; background: url(../img/masterCard.png) no-repeat center; }
.creditCard table { table-layout: fixed; width: 100%; margin: 0 auto; }
.creditCard label { display: inline-block; text-transform: uppercase; font-size: 60%; line-height: normal; }
.creditCard input[type="text"] { text-align: center; width: 100%; }
.creditCard .cardNumber, .creditCard .expDate, .creditCard .holderName { position: absolute; line-height: 28px; left: 10px; right: 10px; }
.creditCard .holderName .control-group, .creditCard .number .control-group  { padding-right: 10px;}
.creditCard .number .control-group input { text-align: left; }
.creditCard .holderName input { text-align: left;}
.creditCard .cardNumber { bottom: 134px; }
.creditCard .cardNumber table { width: auto; table-layout: auto; margin: 0 -5px; }
.creditCard .expDate { bottom: 72px; }
.creditCard .expDate td.expMonth { text-align: right; }
.creditCard .expDate td.expMonthLabel { text-align: right; }
.creditCard .expDate td.expMonthLabel label { width: 42px; text-align: left; }
.creditCard .expDate td.expMonth label { display: inline-block; line-height: 10px; vertical-align: middle; text-align: left; margin: 0 10px 0 0; }
.creditCard .expDate input {text-align: center;}
.creditCard .holderName { bottom: 10px; }
.creditCard .cvv { width: 50px; position: absolute; line-height: 28px; bottom: 126px; right: 22px; }
.creditCard .cvv label { margin-bottom: 5px; }
#layout_options .creditCard .holderName .tw-field { width: 100%; padding-left: 70px; }
#layout_options .creditCard .holderName input { width: 100%;}
#layout_options .tab-pane .creditCard .cardNumber .number1 { padding-left: 0; }

#layout_options .creditCard .cvv input { width: 100%;}
#layout_options .creditCard .cardNumber input { text-align: left; width: 100%;}
#layout_options .creditCard .expDate td.expMonth input, #layout_options .creditCard .expDate td.expYear input { width: 43px; text-align: left; }
#layout_options .tab-pane .creditCard td { padding: 0 5px; vertical-align: middle; }
.agreeRules { margin: 15px 0;}

#cardAuthorization { padding: 0; width: 670px; max-height: 550px; }
#cardAuthorization .title2 { font-weight: bold; margin-top: 0.8em; }
#cardAuthorization .title3 { font-size: 80%; font-weight: bold; }
#cardAuthorization .note { font-size: 90%; }
#cardAuthorization .note.warn { display: inline-block; padding: 0.2em 0.5em; margin-left: -0.5em; }
#cardAuthorization .code { display: inline-block; }
#cardAuthorization .pic { height: 148px; margin: -22px 30px 0 0; background: url('../img/cardAuthorization.png') no-repeat; }
#cardAuthorization .pic.phone { width: 190px; background-position: left top; }
#cardAuthorization .pic.ebank { width: 177px; background-position: -190px top; }
#cardAuthorization .pic.card  { width: 177px; background-position: -367px top; }
#cardAuthorization #form_cardAuthorization { display: inline-block; background-color: #ADD260; padding: 7px 35px 7px 7px; }
#cardAuthorization table td { vertical-align: top; }
#cardAuthorization table.form { margin: 2px 0; }
#cardAuthorization table td.error { vertical-align: middle; padding-left: 20px; }
#cardAuthorization table td.error div { display: inline-block; background: #b80000; padding: 3px 5px; color: white; font-size: 80%; white-space: nowrap; }
#cardAuthorization table.picture { margin: 1em 0; }
#cardAuthorization .tw-field input { height: 30px; padding: 5px 10px; width: 4em; }
#cardAuthorization .tw-field { margin-right: 1ex; }
#cardAuthorization button { margin-top: 0; }
#cardAuthorization input { width: 60px; margin: 0 5px 0 0;}
#cardAuthorization .control-group { margin: 0;}

.hint { z-index:666; position: absolute; min-height: 16px; min-width: 22px; padding: 5px 15px 4px; font-size: 80%; color: black; background: none; }
.hint.error { z-index: 1777; color: white; }
.hint.black { color: white; }
.hint .abs { z-index:-1; top: -7px; right:-9px; bottom:-13px; left:-9px; }
.hint .tl, .hint .tr, .hint .bl, .hint .br { width: 20px; background-image: url('../img/hint/white_corners.png'); }
.hint.error .tl, .hint.error .tr, .hint.error .bl, .hint.error .br { background-image: url('../img/hint/red_corners.png'); }
.hint.black .tl, .hint.black .tr, .hint.black .bl, .hint.black .br { background-image: url('../img/hint/black_corners.png'); }
.hint .tl, .hint .tr { height: 20px; }
.hint .bl, .hint .br { height: 25px; }
.hint .t,  .hint .b { left: 20px; right: 20px; background-image: url('../img/hint/white_tb.png'); }
.hint.error .t,  .hint.error .b { background-image: url('../img/hint/red_tb.png'); }
.hint.black .t,  .hint.black .b { background-image: url('../img/hint/black_tb.png'); }
.hint .t { height: 7px;}
.hint .b { height: 13px;}
.hint .l,  .hint .r { top: 20px; bottom: 25px; background-image: url('../img/hint/white_lr.png'); }
.hint.error .l,  .hint.error .r { background-image: url('../img/hint/red_lr.png'); }
.hint.black .l,  .hint.black .r { background-image: url('../img/hint/black_lr.png'); }
.hint .l { width: 20px;}
.hint .r { width: 20px;}
.hint .c { top: 7px; bottom: 13px; left: 20px; right: 20px; background-color: white; }
.hint.error .c { background-color: #b80000; }
.hint.black .c { background-color: rgba(0,0,0,0.85); }
.hint .arrUp { width: 24px; height: 15px; top: -7px; left: 0; right: 0; margin: 0 auto; background-image: url('../img/hint/white_arrUp.png'); }
.hint.error .arrUp { background-image: url('../img/hint/red_arrUp.png'); }
.hint.black .arrUp { background-image: url('../img/hint/black_arrUp.png'); }
.hint .question { display: inline-block; background-image: url('../img/QuestionIcon.png'); width: 17px; height: 17px; cursor: pointer; }
.hint .text { }
.hint .hiddenText { display: none; margin-top: 10px; }

#PrintTicket {
	padding: 0 40px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 13px;
	color: black;
	font-weight: 400;
}
#PrintTicket .invisible { display: none !important;}

#PrintTicket .hr { height: 20px; position: relative;}
#PrintTicket .hr:after {content: ""; display: inline-block; position: absolute; top: 9px; border-bottom: 2px dashed black; height: 0; width: 100%; }
#PrintTicket .receipt_header table, #PrintTicket .trip table { width:100%; border-collapse: collapse;}
#PrintTicket .trip { page-break-inside: avoid;}
#PrintTicket .trip table td {padding: 0;}
#PrintTicket .trip table td:first-child { width: 130px;}
#PrintTicket .trip table td:nth-child(2) { width: 150px; padding: 0 10px 0 20px;}
#PrintTicket .trip table .line td { padding: 0;}
#PrintTicket .trip table .line div { height: 10px; position: relative;}
#PrintTicket .trip table .line div:after {content: ""; display: inline-block; position: absolute; top: 4px; border-bottom: 1px dashed black; height: 0; width: 200%; margin-left: -50%;
	-webkit-transform: translateZ(0) scale(0.5, 0.5);
	-moz-transform: translateZ(0) scale(0.5);
	-o-transform: translateZ(0) scale(0.5);
	transform: translateZ(0) scale(0.5);
}

#PrintTicket .trip table .flight_info td { padding-top: 10px; padding-bottom: 10px; line-height: 14px; font-weight: 200;}
#PrintTicket table td { vertical-align: top; }
#PrintTicket table .right {text-align: right;}
#PrintTicket table .logo_cell { width: 130px; text-align: right; padding: 0 18px 10px 0;}
#PrintTicket table .logo_cell * { margin-top: -8px;}
#PrintTicket table .page { position: relative; font-weight: bold; font-size: 15px; }
#PrintTicket table .page span { position: absolute; right: 0; top: -10px;}
#PrintTicket .receipt { font-size: 23px; padding: 3px 0 15px; font-weight: 300;}
#PrintTicket .receipt_header {text-transform: uppercase; font-size: 11px; padding-top: 50px;}
#PrintTicket .agent { text-transform: none; font-weight: 300; }
#PrintTicket .order_info { font-size: 15px;}
#PrintTicket .passenger_info { font-weight: bold; font-size: 17px;}
#PrintTicket .time, .point { font-size: 17px;}
#PrintTicket .fw1 { font-weight: 100 !important;}
#PrintTicket .fw2 { font-weight: 200 !important;}
#PrintTicket .fw3 { font-weight: 300 !important;}
#PrintTicket .fw4 { font-weight: 400 !important;}
#PrintTicket .fw5 { font-weight: 500 !important;}
#PrintTicket .fw6 { font-weight: 600 !important;}
#PrintTicket .up { text-transform: uppercase; }

#PrintTicket .code_box { font-size: 27px; display: inline-block; padding: 7px; border: 2px solid; }
#PrintTicket .price_notice { font-size: 9px; line-height: 12px; padding-top: 30px; text-transform: uppercase; page-break-inside: avoid; page-break-before: none; page-break-after: none;}
#PrintTicket .price_notice .important { font-size: 13px; padding-bottom: 5px; font-weight: bold; }
#PrintTicket .price_info { text-transform: uppercase; page-break-inside: avoid; page-break-before: none; page-break-after: none;}
#PrintTicket .price_info table td { color: black !important; padding-top: 0; padding-bottom: 0; }
#PrintTicket .price_info table tr { height: auto !important;}
#PrintTicket .price_info table tr.p20 td:first-child { padding: 0 !important;}
#PrintTicket .price_info table.pass_info tr:first-child, .price_info table.pass_info tr.h10, .price_info .dashedBorder { display: none; }
#PrintTicket .price_info table.pass_info .total_price {border: none; font-size: 17px; font-weight: bold;}
#PrintTicket .price_info table.pass_info .total_price td { height: auto; }
#PrintTicket .break {page-break-before: always; height: 0px; line-height: 0px;}
#PrintTicket .totalExplained { text-transform: none; }
#PrintTicket .receipt_th div { height: 0px; line-height: 0; border-bottom: 2px solid black; margin: 10px 0 8px; }
#PrintTicket .FarePrice { padding: 10px 0 0;}
#PrintTicket .price_info table.pass_info { margin: 0;}
#PrintTicket .monreal { text-transform: none; }

@media screen {
	#PrintTicket { display: none; }
}
@page {
  size: auto;
  margin: 0mm;
}
@media print {
  html, body {
    width: 210mm;
    height: 285mm;
    padding: 0mm;
    margin: 0mm;
  }
/*  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }*/
  #content, #footer, #ui-datepicker-div { display: none !important; }
}

table.simple { width: 100%; max-width: 100%; border-spacing: 0; border-collapse: collapse; table-layout: fixed;}
table.simple td { text-align: left; vertical-align: top; }
table.simple .cell {position: relative;}
table.simple .cell .h_devider {position: absolute; left: -60px; top: 5px; bottom: 5px; width: 1px; background-color: #EDEDED; font-size: 0;}
table.simple .cell ul { list-style: none; }
table.simple .cell ul li { font-size: 130%; font-weight: bold; margin: 10px 0; }
table.simple .cell ul li * { vertical-align: middle;}

#tw-loader {
	background: url('../img/loader.gif') no-repeat left center;
	padding-left: 45px;
	line-height: 38px;
	height: 38px;
}

.tw-dots * { vertical-align: middle; }

.tw-form { margin-top: 20px; }
.tw-form input[type="text"] { width: 240px; }
.TripBlock .submit * { vertical-align: top;}
.tw-form .submit .alert, .TripBlock .submit .alert { margin: 0 0 0 10px;}
.submit button {  margin: 6px 0;}
form .alert, .TripBlock .alert { display: inline-block;//display: inline; margin: 0 0 0 10px;}

#orderForm { margin-bottom: 10px;}
.dateMask { width: 75px;}
.loader { display: inline-block; //display: inline; margin: 0 0 0 30px; }

.top_space { padding-top: 10px;}

.h_spacer1 { height: 20px;}
.h_spacer2 { height: 15px;}
.show_more { margin-left: 20px; font-size: 80%;}

.PassengersList {color: black;}
.PassengersList td { padding: 5px 10px;}
.PassengersList td.first { padding: 5px 0;}
.PassengersList .label { white-space: nowrap;}

#layout_ticket { clear: both; }
#layout_ticket .passenger { font-size: 165%; color: black; margin: 3px 0 10px; line-height: 32px; }
#layout_ticket .passenger span { text-transform: uppercase; }
#layout_ticket .pas_numbers { font-size: 144%; color: black; padding: 10px 0; display: inline-block; }
#layout_ticket .bookingUrl { padding: 10px 5px; display: inline-block; }
#layout_ticket .ticket_status { padding: 20px 0 10px; color: black; font-size: 140%; }
#layout_ticket .ticket_status form {margin: 10px 0 0 0;}
#layout_ticket .ticket_status .note { font-size: 80%; margin-top: 10px;}
#layout_ticket .ticket_status .requestOptButtons { clear: both; padding: 0;}
#layout_ticket .ticket_status .requestOptButtons .loader, #layout_ticket .ticket_status .requestOptButtons .alert { float: right;}

.dfp-banner { padding-top: 16px; }
.promo-sidebar--right.dfp-banner { padding-top: 8px; }

.banner-popup__overlay {
	display: none;
	position: fixed;
	z-index: 10;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(33, 33, 33, 0.6);
	transition: opacity .2s ;
}

.banner-popup__layout {
	display: flex;
	padding: 16px;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	overflow-y: auto;
	justify-content: center;
}

.banner-popup {
	position: relative;
	top: 10%;
	height: 486px;
	width: 400px;
	box-sizing: border-box;
	background-color: #ffffff;
	overflow: hidden;
	border-radius: 8px;
	box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.2);
}

.banner-popup .dfp-banner {
	padding: 0;
}
.close-icon {
	position: absolute;
	right: 16px;
	top: 16px;
	opacity: 0.5;
	transition: opacity .2s ease-in-out;
	cursor: pointer;
}
.close-icon:hover {
	opacity: 1;
}

.dashedBorder { position: relative; height: 2px; margin: -1px 0 0 -35px; background: url('../img/dashes-h.png') repeat-x center; }

.visa_block { padding: 8px 0; }
.visa_messages .alert { display: inline-block; margin: 10px 0; }
.visa_form, .visa_display { display: none; width: 570px; padding: 20px 0; }
.visa_display { width: auto; padding-top: 0; }
.visa_form .alert.instructions { padding-right: 14px; margin: 5px 0 0 0;  border-color: #FFCE00; color: black; background: #FFE958; }
.visa_form fieldset { margin: 10px 0; }
.visa_form .control-group { margin-bottom: 0; }
.visa_form .legend, .visa_display h3 { margin-bottom: 10px; border-bottom: 1px solid #EEE; font-size: 21px; font-weight: normal; line-height: 36px; color: #333; }
.visa_form fieldset.submit { height: 38px; }
.visa_form fieldset.submit #tw-loader, .visa_form fieldset.submit .alert { display: inline-block; width: 310px; margin: 3px 0 0 20px; vertical-align: top; }
.visa_form fieldset.passenger_origin { margin-top: 0; }
.visa_form button.cancel { margin-left: 20px; }
.visaInfoBlock, .visa_display .row { margin-top: 15px; margin-bottom: 15px; }
.show_visa_display { margin-right: 20px; }

.meal_block { padding-top: 20px; min-height: 50px; }
.meal_block.empty { min-height: 0; padding: 0; }
.meal_block .dashedBorder,
.vas_block .dashedBorder,
.seatmap_block .dashedBorder {
  margin-top: 20px;
}
.meal_form { display: none; }
.meal_form label { margin-right: 10px; }
.meal_form button { margin: 0 0 0 10px; }
.meal_form button.btn-primary { margin-left: 20px; }
.meal_form.form-inline fieldset.submit { display: inline-block; }
.meal_form fieldset.submit { position: relative; }
.meal_form.form-horizontal fieldset.submit { display: inline-block; }
.meal_form fieldset.submit #tw-loader { margin-left: 20px; position: absolute; left: 100%; top: -5px; }
.meal_form .disclaimer { margin: 10px 0 0 0; width: 662px; padding-right: 14px; }
.meal_form.form-horizontal .control-label { width: 270px; }
.meal_form.form-horizontal .controls { margin-left: 290px; }
.meal_form.form-horizontal button.btn-primary { margin-left: 0; }
.meal_form.form-horizontal fieldset.submit { padding-left: 290px; }
.meal_display .selected_meal { text-transform: lowercase; }
.meal_block .too_late { display: inline-block; margin-bottom: 0; }

#showUnpaidReservation { margin-top: 10px; }
.unpaid_reservations_block { display: none; font-size: 0.7em; }
.unpaid_reservations_block .ssl_assurance { font-size: 0.857em; padding-left: 20px; position: relative; }
.unpaid_reservations_block .icon-lock { position: absolute; left: 0; }
.unpaid_reservations_block .creditCard label { font-size: 0.7em; }
.unpaid_reservations_block .cardNumber td { padding: 0 5px; }
.unpaid_reservations_block .expDate td { padding: 0 5px; }
.unpaid_reservations_block .expDate input { width: 33px; }
.unpaid_reservations_block .cvv { width: 64px; right: 25px; }
.unpaid_reservations_block .cvv input { width: 54px; }
.unpaid_reservations_block button.cancel { margin-left: 20px; }
.unpaid_reservations_block #tw-loader, .unpaid_reservations_block .alert  { display: inline-block; margin: 3px 0 0 20px; vertical-align: top; }
.unpaid_reservations_block #tw-loader { float: none; }

.popover.validation { width: auto; max-width: 235px; padding: 0; border: none; font-size: 11px;
	-webkit-border-radius: 13px;
	   -moz-border-radius: 13px;
			border-radius: 13px;
	-webkit-box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.3);
	   -moz-box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.3);
			box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.3);
}
.popover.validation.error { background: #b80000; color: white; }
.popover.validation.warning { background: #FFE958; }
.popover.validation.bottom { margin-top: 0; }
.popover.validation.bottom .arrow { width: 24px; height: 15px; margin: 0 auto; left: 0; right: 0; top: -15px; border: none; background-image: url('../img/hint/white_arrUp.png'); }
.popover.validation.bottom.error .arrow { background-image: url('../img/hint/red_arrUp.png'); }
.popover.validation.bottom.error .arrow:after { border-width: 0; }
.popover.validation .popover-inner { width: auto; }
.popover.validation .popover-title { display: none; }
.popover.validation .popover-content { padding: 3px 10px 5px 10px; }
.popover.validation .popover-content p { font-size: 11px; }

.typeahead.dropdown-menu a { text-decoration: none; }

.ticketAccess { margin: 15px 0 0;}

#changeDocument { white-space: nowrap; }
.passenger-change-document { display: none; }
.passenger-change-document .control-group { margin-bottom: 0; }
.passenger-change-document>.control-group { display: inline-block; vertical-align: middle; }
.passenger-change-document .alert { display: inline-block; margin-bottom: 0;}
#documentExpire input { width: 90px;}
#documentExpire .checkbox { float: left; }
#documentExpire .checkbox input { width: auto; outline: 0; }
#documentExpire { margin: 0 20px; }
#input_documentNewNumber { width: auto; }
.passenger-change-document .submit { margin-top: 15px;}

#refreshBlock { margin: 0 0 0 20px; white-space: nowrap; }
#newInfo { margin-left: 20px;}

#layout_options { padding-top: 20px; clear: both; }
.options { background: url('../img/options.png') no-repeat 0 0; width: 30px; height: 32px; display: inline-block; margin-right: 15px;}
.options.status { background-position: 0 0;}
.options.details { background-position: -30px 0;}
.options.print { background-position: -120px 0;}
.options.return { background-position: -90px 0;}
.options.change { background-position: -60px 0;}
.options.food { background-position: -150px 0;}
.options.question { background-position: -180px 0;}

.tabs-left .nav-tabs .active > a, .tabs-left .nav-tabs .active > a:hover {
	background-color: #eeeeee;
}
#layout_options .big { font-size: 110%;}
#layout_options .tabs-left { padding-left: 30px;  }
#layout_options ul.nav .options { float: left; margin: 0 0 0 -30px;}
#layout_options ul.nav li { margin: 10px 0;}
#layout_options ul.nav, #layout_options .name { font-size: 156%; }
#layout_options ul.nav { margin-right: 0px; border: 0px none;}
#layout_options .tab-pane { border-left: 1px solid #DDDDDD; min-height: 130px; margin-top: 10px;}
#layout_options .tab-pane .options { margin: 0;}
#layout_options .tab-pane ul li * { vertical-align: middle; }
#layout_options .tab-pane table { margin: 0px; }
#layout_options .tab-pane table td { padding: 8px 15px;}
#layout_options .tab-pane .qList { clear: both; }
#layout_options .tab-pane .qList table td * { vertical-align: middle; }
#layout_options .tab-pane .head table td * { vertical-align: middle;}
#layout_options .tab-pane .name {  /*max-width: 540px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; */ display: inline-block; //display: inline; margin-right: 20px; color: black;}
#layout_options .tab-pane .nameWrap { position: relative;}
#layout_options .tab-pane .nameWrap .tw-dots { position: absolute; right: 0; top: 0px; }

#layout_options .tab-pane .requestHead { /*padding-bottom: 5px;*/ margin:0 0 10px 0; display: inline-block;}
#layout_options .tab-pane .requestBody { display: none; padding-bottom: 15px; }
#layout_options .tab-pane .requestBody .cancel-ticket-passenger {
	padding: 20px 0 10px;
}
#layout_options .tab-pane .requestBody .cancel-ticket-passenger div {
	padding-bottom: 10px;
}
#layout_options .tab-pane .requestBody .cancel-ticket-passenger input {
	margin: 0 10px 0 18px;
}
#layout_options .tab-pane .requestBody .cancel-ticket-passenger label {
	display: inline-block;
	margin: 0;
}
#layout_options textarea.well { padding: 5px; width: 300px; height: 70px; margin-bottom: 18px;}
#layout_options .requestBlock { display: none;  padding: 8px 15px 0 15px; }
#layout_options .requestBlock form { margin: 0;}
#layout_options .requestBlock .greetings { padding-bottom: 10px;}
#layout_options .requestBlock .greetings b {text-transform: capitalize;}
#layout_options table.hover tbody tr:hover td, #layout_options table.hover tbody tr:hover th {
  background-color: #f5f5f5;
  cursor: pointer;
}
#layout_options table.hover {
	border-top: 1px solid #DDDDDD;
}
#layout_options .tw-form textarea { width: 400px;}
#layout_options .toggleOperation { padding: 8px 15px; display: none; }
#layout_options .toggleOperation .name { margin: 0;}

.comment-group { padding: 0px 0 5px 0; color: black;}
.infoHead { font-weight: bold; font-size: 110%; }

#info-returns .accordion .accordion-toggle { font-size: 110%;}
#info-returns .control-group { margin-bottom: 18px;}
#info-returns .comment-group p { margin: 5px 0; color: #666666;}
/*#info-returns .submit .btn { margin-top: -5px;}*/
#info-returns .control-group .big { font-size: 120%; }
.requestOptButtons { padding: 10px 0;}
#returnTypes { display: none;}
.infoSelection { display: none;}

#QuestionForm .control-group label {
	display: inline-block;
	margin-right: 20px;
}

.dialogue { display: none; padding: 10px 0 18px 15px;}
.dialogue .dashedBorder {margin: 0 0 5px 0;}
.dialogue .label, .message .label { width: 100%; padding: 0;
	-webkit-border-bottom-left-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-webkit-border-radius: 3px 3px 0 0;
	   -moz-border-radius: 3px 3px 0 0;
			border-radius: 3px 3px 0 0;
}
.dialogue .ownerInfo, .message .ownerInfo { padding: 2px 5px 3px;}
.dialogue .commentTime { float: right; }
.dialogue .comment, .message .comment { margin-bottom: 15px; padding: 15px;
	-webkit-border-top-left-radius: 0;
	-webkit-border-top-right-radius: 0;
	-webkit-border-radius: 0 0 4px 4px;
	   -moz-border-radius: 0 0 4px 4px;
			border-radius:0 0 4px 4px;
}
.dialogue .title { font-size: 120%; font-weight: bold;}
.dialogue form { display: none; }
.dialogue form.commentForm { padding: 0px;}
#layout_options .tab-pane .dialogue .payInfo { line-height: normal;}
#layout_options .tab-pane .dialogue .payInfo table td {padding: 0px;}
#layout_options .tab-pane .dialogue .payInfo table td.price { padding-left: 20px; text-align: right; font-weight: bold;}
#layout_options .tab-pane .dialogue .payInfo table td.dashedSpace { padding: 5px 0;}
#layout_options .tab-pane .dialogue .payInfo table tr.top_sp td { padding-top: 10px;}

#layout_options #cardBlock { display: none; }
#layout_options #cardBlock .returnPrice { padding-right: 15px; }
#layout_options .cancellationSum { padding: 10px 0; }
#layout_options .cancellationCardForm .submit {text-align: right}

.tripFlight { padding-top: 5px;}
.tripFlightInfo { font-size: 80%;}

#change_variants, #change_selected { padding-right: 7px; }
#change_variants { display: none; }
#change_selected { display: none; padding-bottom: 10px; }
#change_selected .searchResult { display: none;}
#cancelForm { padding: 15px 0;}
#voidReasonBlock { margin: 10px 0; display: none;}
#voidReasonBlock .alert { margin-bottom: 10px;}
#voidReasonBlock .control-group {padding-left: 15px; margin-top: 10px;margin-bottom: 15px;}
#confirmationStep {display: none;}
#show_change_info { display: none; }
#show_change_info .loader { margin: 0;}
#change_info { padding-top: 10px; }
#makeChangeRow { padding: 10px 0; margin-right: 20px;}
.changeSearch { position: absolute; right: 0.8em ; top: 3px;}
.change_direction { display: none; padding: 10px 0 0 0; font-size: 13px;}
.change_direction .routeHeader { font-size: 140%; padding: 10px 10px 10px 0; color: #444444; }
.date_ico {cursor: pointer;}
#returnOptions form .message .comment-group { padding: 0 15px; margin: 0;}
#change_surcharge { display: none; padding: 0;}

.FarePrice { padding: 10px 0 0;}
.FarePrice table td.sum { text-align: right; padding: 0 10px;}
.FarePrice .total_price .sum { padding: 0;}
.FarePrice table td.sum div {float: left;}
.FarePrice table td.name { width: 330px;}
.FarePrice .title { color: black; font-size: 105%;}

.FarePrice table tr {
	height: 25px;
}
.FarePrice .pass_info {
	margin-top: 15px;
	margin-bottom: 15px;
}
.FarePrice .pass_info tr:first-child {
	border-bottom: 1px solid gray;
}
.FarePrice table .p20 {
	color: #C0C0C0;
}
.FarePrice table .p20 td.name {
	width: 340px;
	padding-left: 20px;
}
body .FarePrice table td.name {
	width: 360px;
}
.FarePrice .total_price {
	border-top: 2px solid gray;
}

.FarePrice table tr.h10{
	height: 10px;
}
.FarePrice .total_price td {
	font-size: 20px;
	height: 45px;
}


.totalExplained { margin: 5px 0; }
.totalExplained strong { color: black; }
.totalExplained ul.transactions { font-size: 0.875em; }

.resize .label {cursor: pointer;}
.resize .icon-resize-full { float: right; display: none; margin-top: 2px;}
.resize { padding-bottom: 20px;}
.resize .well { margin: 0;}

.returnAgreement { padding: 10px 0;}
.svyaznoi_bkg { background-color: #fec833; color: black;}
.psb_bkg {border-color: #DD4F01; border-width: 2px; color: #003399;}
.psb_color { color: #DD4F01;}

#questionsModal { width: 900px; margin-left: -450px; max-height: 550px; height: 550px; background-color: #F5D244; }
#questionsModal h3 { color: #512609; }
#questionsModal .modal-body { padding: 15px 0; max-height: 410px;}
#questionsModal .modal-header { border-bottom: 1px solid #512609; }
#questionsModal .modal-header h3 {font-size: 30px;}
#questionsModal .modal-footer { background-color: #F5D244; border-color: #512609; box-shadow: none; }
.questionsContainer, .questionsContainer iframe { width: 880px; height: 415px; }
.questionsContainer #tw-loader { width: 38px; padding-left: 0; margin: auto; position: absolute; left: 0; right: 0; top: 0; bottom: 0; }
.questionsContainer iframe body { background-color: white !important;}

.timeToPay { font-weight: bold;}
.timeToPay .blink { font-weight: normal;}
#OneTimePasswordModal { width: 400px; margin-left: -200px; }
.modal { width: 600px;}
.modal ul { margin: 10px 20px; }
.modal li { margin: 5px 0; list-style: disc; }
.modal.paymentError { width: 690px;}

.emptystar, .graystar, .halfgraystar { display: inline-block; height: 14px; width:14px; vertical-align: middle; }
.emptystar {background: url('../img/stars/EmptyStar.png') repeat-x;}
.graystar {background: url('../img/stars/GrayStar.png') repeat-x;}
.halfgraystar {background: url('../img/stars/HalfGrayStar.png') no-repeat;}
.stars_baloon ul { list-style-type: none; padding: 0px; margin: 0px;}
.stars_baloon ul li { display: block; margin: 0px; padding: 1px 0px; white-space: nowrap; }
.stars_baloon ul li div { display: inline-block;//display: inline;}
.stars_baloon .info_stars { background: url('../img/stars/BaloonStar.png') repeat-x; height: 13px; width:14px;}
.stars_baloon .info_half_stars { background: url('../img/stars/BaloonHalfStar.png') no-repeat; height: 13px; width:14px;}
.stars_baloon .info_empty_stars { background: url('../img/stars/BaloonGrayStar.png') repeat-x; height: 13px; width:14px;}
.stars_baloon .comment {margin-left: 10px; font-size: 13px}
.stars_baloon .noStarsComment {margin-left: 80px;}
.revert .content .noStarsComment {margin-left: 10px;}
.stars_block { display: inline-block;//display: inline; font-size: 80%; cursor: help;}

#rotationPanel { margin: 0 auto; text-align: center; }
.social { padding-left: 50px; display: inline-block; color: white; font-size: 90%;}
.social * {vertical-align: middle;}
.social a { width:21px; height:21px; display: inline-block; background: url('../img/social_buttons.png') no-repeat left top; cursor: pointer; }
.social .fb {background-position: -21px 0;}
.social .tw {background-position: -42px 0;}
.social .vk {background-position: 0 0;}

.suggest {
	z-index: 78; position: absolute;
	background: white; color: black;
	border: 1px solid #535353;
	-webkit-border-bottom-left-radius: 7px;
	-webkit-border-bottom-right-radius: 7px;
	-webkit-border-radius: 0 0 7px 7px;
	   -moz-border-radius: 0 0 7px 7px;
			border-radius: 0 0 7px 7px;
	-webkit-box-shadow: 10px 10px 14px 14px rgba(0,0,0,0.4);
	   -moz-box-shadow: 10px 10px 14px 14px rgba(0,0,0,0.4);
			box-shadow: 10px 10px 14px 14px rgba(0,0,0,0.4);
}
.suggest ul { display: block; list-style: none; margin:0; padding:0; }
.suggest li { display:block; margin:0; padding: 0.4em 10px; }
.suggest li:last-child {
	-webkit-border-bottom-left-radius: 7px;
	-webkit-border-bottom-right-radius: 7px;
	-webkit-border-radius: 0 0 7px 7px;
	   -moz-border-radius: 0 0 7px 7px;
			border-radius: 0 0 7px 7px;
}
.suggest li:hover, .suggest li.hover {
	cursor: pointer;
	background: #78a8cc url(/images/suggestHoverRow.gif) repeat-x top; background-size: 100% 100%;/* IE & Old */
	/* @alternate */ background: -webkit-linear-gradient(top, #9cc1de, #bbdbf4 1px, #78a8cc);
	/* @alternate */ background:    -moz-linear-gradient(top, #9cc1de, #bbdbf4 1px, #78a8cc);
	/* @alternate */ background: 	-ms-linear-gradient(top, #9cc1de, #bbdbf4 1px, #78a8cc);
	/* @alternate */ background: 	 -o-linear-gradient(top, #9cc1de, #bbdbf4 1px, #78a8cc);
	/* @alternate */ background: 		linear-gradient(to bottom, #9cc1de, #bbdbf4 1px, #78a8cc);
}

.suggest span { color: #7f7f7f; }
.suggest table { border-collapse: collapse; width: 100%; }
.suggest table td { padding: 0; vertical-align: baseline; }
.suggest table td.code { color: #7f7f7f; font: small "Lucida Console", Monaco, monospace; width: 3ex; padding-left: 1em; }

.servicePacks { padding: 15px 0 5px 0;}
.servicePacks b { color: black; }
.servicePacks ul {margin: 5px 0;}
.servicePacks .label { margin: 0 5px;}

/*bootstrapsOverwrites*/
h1 {
	font-size: 26px;
	line-height: 36px;
	color: #333333;
	margin: 0;
}
.label {padding: 2px 4px 3px;white-space: normal; line-height: inherit;}
body {line-height: 18px;}

.normal-list {
	 padding: auto;
	 margin-left: 30px;
	 list-style: circle;
}

.normal-list .indent.margin {
    margin-left: 30px;
    list-style-type: disc;
}

.price_color {
	color:#fd8021;
	font-weight: 400;
}

.large_subheading {
	font-size: 21px;
	color: black;
	line-height: 28px;
	display: inline-block;
}

.large_subheading2 {
	font-size: 15px;
	color: black;
	line-height: 22px;
}

.large_subheading3 {
	font-size: 15px;
	color: grey;
	line-height: 22px;
}

a.with_icons {
	padding-left:5px;
}

 .table.no-bottom-border td  {
	 border-top: none;
 }

 .table.no-bottom-border tr:last-child {
	border-top: 1px solid #dddddd;
 }

 .sidebar {
 		position:absolute;
 		right: 0;
 		top:0;
		width:190px;
		height: 100%;
		background-color: #EEEEEE;
		background-image: url('../img/footer_bg_vertical.jpg');
		background-repeat: repeat-y;
		background-position: 0 0;
		padding:5px 0 0 20px;
 }

 .sidebar >.sidebar_content > .sidebar-element{
	margin-bottom:30px;
	float: left;
	background-color: #FD8021;
	width: 170px;
	height: 173px;
 }
.green_btn {
	color: white;
	background-color: #31B404;
	border-radius: 5px;
	font-size: 15px;
	width: 110px;
	text-align: center;
	height: 20px;
	padding: 5px 0 3px 0;
	margin: 15px 0 10px 10px;
	cursor: pointer;
}
.threeds_text {
	margin: 10px 0 0 10px;
	line-height: 21px;
}
@media screen and (max-width: 1050px) {
 	.sidebar {
  	display:none;
	}
}
#Modal .modal-body .modal_body_status {
	color: gray;
	text-align: right;
	margin: 20px 0 0 0;
}

.railwayOrder{
	color:#333333 !important;
}
.railwayOrder .railwayOrder__hotelWidget {
	left: 0 !important;
}
.railwayOrder .lead {
	vertical-align: middle;
}

.railwayOrder table.fare-table td {
	padding:8px;
}

.railwayOrder table.fare-table .row-title{
	font-size: 16px;
	min-width: 130px;
}

.railwayOrder .fare-table .text-right{
	text-align: right;
	white-space: nowrap;
}

.railwayOrder .fare-table .text-left{
	text-align: left;
}

.railwayOrder .fare-table .row-data{
	font-size: 16px;
	min-width: 100px;
	padding: 0 5px;
}
.railwayOrder .alert .btns{
	text-align: right;
}

.railwayOrder .alert {
	border-left: 5px solid #666666;
	border-right: 0;
	border-bottom: 0;
	border-top: 0;
	border-radius: 0;
	text-shadow: none !important;
	background-color: #D8D8D8;
	color: #333333;
	padding-right: 9px;
	padding-left: 23px;
}
.railwayOrder .alert button {
	float:right;
}

.railwayOrder .fare-table .with-dash{
	background: url('../img/dashes-h.png') repeat-x top;
}

.railwayOrder .cancel-info-block-container .btn {
  margin: 2px;
}

.railwayOrder .cancel-info-block-container{
	margin-top:20px;
	max-width:500px;
}

.railwayOrder .ticket-info-container{
	max-width: 720px;
	font-size: 13px;
	margin-top: 5px;
}

.railwayOrder .letters-were-changed-warning {
	margin-top: 5px;
	font-size: 13px;
}

.railwayOrder .passenger-block{
	margin-bottom: 20px;
}

.railwayOrder .passenger-block .transit-document-info {
	margin-top: 5px;
	font-size: 13px;
}

.railwayOrder .passenger-block .transit-document-info .transit-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.railwayOrder .passenger-block .transit-document-info .transit-wrapper.description {
	padding-left: 10px;
}

.railwayOrder .passenger-block .transit-document-info .transit-wrapper .tansit-status {
	border-radius: 50%;
	width: 7px;
	height: 7px;
	margin: 0;
}

.railwayOrder .passenger-block .transit-document-info .transit-wrapper .tansit-status.success {
	background-color: #3fcc62;
}

.railwayOrder .passenger-block .transit-document-info .transit-wrapper .tansit-status.wait {
	background-color: #ffd41e;
}

.railwayOrder .passenger-block .transit-document-info .transit-wrapper .tansit-status.declined {
	background-color: #e74c3c;
}

.railwayOrder .action-link:hover{
	cursor: pointer;
}
.railwayOrder .train_name {
	font-size: 17px;
	padding: 3px 0;
}
.railwayOrder .train_type {
	font-size: 15px;
	padding: 3px 0;
}

.railwayOrder .refundInfoBlock {
	padding: 20px;
}

.railwayOrder .refundInfoBlock .refundInfoBlock__header {
	margin-bottom: 20px;
}

.railwayOrder .refundInfoBlock .info-text {
	margin-bottom: 20px;
}

.railwayOrder .refundInfoBlock .description {
	margin-bottom: 20px;
}

.railwayOrder .refundInfoBlock .heading--total {
	margin-bottom: 4px;
	font-weight: bold;
}

.railwayOrder .refundInfoBlock .heading--total .refundAllOnly {
	font-weight: normal;
}

.railwayOrder .passenger-refund-check-status {
	margin-top: 10px;
	font-weight: bold;
}

.ticket-info-container .btn {
	margin-top: 10px;
}

.prepay {
	font-size: 17px;
	color: black;
	margin-top: -3px;
	border-bottom: 1px solid  #eee;
	padding-bottom: 13px;
}
.payment_block hr{
	margin: 13px 0;
}
.payment_block, .payment_block b{
	font-size: 15px;
}
.pay_item_title {
	margin: 20px 0 0;
	padding: 8px 0;
	border-bottom: 1px solid  #eee;
}
.pay_item {
	border-bottom: 1px solid  #eee;
	padding: 13px 0;
}
.pay_item div {
	float: right;
}
.pay_price {
	color:#fd8021;
	font-weight: bold;
	font-size: 22px;
}
.bonus_price, .main_price,
.promo_price {
	color: #1167be;
}
.bonus_price, .promo_price {
	font-size: 18px;
}
.main_price{
	font-weight: bold;
	font-size: 20px;
}
.sale_price{
	color: #c52817;
	padding-left: 5px;
	font-weight: bold;
}

.order-header {
    float: left;
    clear: both;
    width: 100%;
}
.red_table td:first-child {
	color: #d20000;
}

.comib { display: inline-block; outline: none; }
#adfox_14968343856942635 { width: 608px; height: auto; margin: 0px auto;}
#adfox_14968343856942635 img { padding-top: 50px;}
.contentLayoutOrder {
	float: left;
	width: 100%;
	clear: both;
}


/* CarRentInfo */
#cars--cancel__list {
	list-style-type: initial;
	margin: 5px 0 10px 25px;
}

#layout_carRentInfo {
	display: none;
	margin-bottom: 15px;
	line-height: 20px;
	color: #222;
}

.cr-first-row {
	display: inline-block;
	padding: 4px 0 5px;
	margin-bottom: 5px;
}

	.cr-first-row .capitalize {
		text-transform: uppercase;
	}

.cr-label-wrapper {
	margin-left: 10px;
}
.cr-link-dashed {
	display: inline-block;
	margin: 10px 0 15px;
	text-decoration: none;
	border-bottom: 1px dashed;
	cursor: pointer;
}
	.cr-link-dashed:hover {

		text-decoration: none;
	}

.cr-buttons {
	margin: 0 0 16px;
}

.cr-buttons .btn {
	margin-right: 10px;
}
	.cr-buttons .btn i {
		margin-right: 5px;
	}

.cr-cancel-item {
	line-height: 1.2;
}
.cr-cancel-item + .cr-cancel-item {
	margin-top: 8px;
}

.cr-cancel {
	position: relative;
	background: #D8D8D8;
	width: 662px;
	padding: 24px 24px 8px 24px;
	margin-top: 16px;

	-webkit-transition: opacity .5s;
	-moz-transition: opacity .5s;
	-o-transition: opacity .5s;
	transition: opacity .5s;
}

	.cr-cancel--isBlocked:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .4;
		background-color: #fff;
	}

	.cr-cancel-btns {
		text-align: right;
		margin-top: 8px;
	}
		.cr-cancel-btns .btn {
			margin-left: 4px;
		}

	.cr-cancel:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 5px;
		background: #979797;
	}

	.cr-cancel--hidden {
		display: none;
	}

#crInfoModal .modal-body {
	padding: 24px 24px 200px 24px;
}

#crInfoModal {
	display: none;
	width: 682px;
	font-size: 14px;
	line-height: 20px;
}

.cr-showOnCancelAvailability {
	display: none;
}
/* end of carRentInfo */

.s7-highlight {
	color: red;
}

#layoutOrderHotels {
	/*margin-left: -60px;*/
}

.l-packages .options-nav{

	margin-left: 32px;
}

/* new design overrides */
.label {
	font-weight: normal;
	text-shadow: none;
}

.label-success {
	background-color: #59B41A;
}
.label-inProgress {
	background-color: #87cefa;
}
.label-cancelled {
	background-color: #999999;
}

.clearFix:after {
	content: "";
	display: table;
	clear: both;
}

/* PassengerList update */
.passengerListWrapper {
	display: inline-block;
	margin: 8px 0;
	border: 1px solid #DFDFDF;
	border-radius: 4px;
	clear: both;
}

#PassengersList td {
	padding: 12px;
}

#PassengersList td:nth-child(2) {
	padding-left: 24px;
	padding-right: 24px;
}

#PassengersList tr {
	border-top: 1px solid #DFDFDF;
}

#PassengersList tr:first-child {
	border-top: none;
}

#debug_env_id {
	position: fixed;
	top: 16px;
	left: 16px;
	padding: 8px;
	z-index: 100;
}

#debug_env_id:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	border-radius: 3px;
	opacity: .6;
	z-index: -1;
}

#debug_env_id .info_row {
	color: tomato;
	font-size: 13px
}

#debug_env_id .info_row_title {
	color: greenyellow;
	font-size: 13px
}

#adfox_149684823431355244,
#adfox_149761008572487645 {
	width: 730px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	overflow: hidden;
}

#adfox_149684823431355244:first-child,
#adfox_149761008572487645:first-child {
	margin-top: 28px;
}

#adfox_15010836842855203 img {
	margin-bottom: 20px;
}

.warning-message {
  max-width: 100%;
  margin-top: 12px;
}

.shortcut-options {
	padding: 20px 0 0;
}
.shortcut-options  button {
	margin-right: 20px;
}

.bus-order_loader {
	background: url('../img/loader.gif') no-repeat left center;
	width: 24px;
	height: 24px;
	display: none;
	background-size: contain;
	vertical-align: middle;
}

.bus-order_loader.show {
	display: inline-block;
}

.bus-order .ticket-info-container {
	padding: 10px 0 0;
}

.bus-order .ticket-info-container .btn {
	margin: 0;
}
